.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  width: 20em;
}

.ant-card {
  margin: 1em 1em;
  flex: 1 0 20em;
}
.ant-card .ant-card-actions {
  background-color: #fafafa;
}

.ant-card-body {
  margin-top: 0;
}

@media (max-width: 300px) {
  .card {
    width: 10em;
  }
}

.info .info__ {
  display: flex;
  align-items: center;
}

.info .info__ > * {
  margin: 0;
}

.info > h2 {
  margin-top: 0;
  margin-bottom: 0.3em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
